import { FREE_TRIAL_DAYS } from '../../../constants/premium';
import { IAccordionData } from '../../Accordion/Accordion';

export const faqData: IAccordionData[] = [
  {
    id: '1',
    title: 'Will you share my catch locations?',
    body: 'When logging in a new catch, you can select from the three levels of privacy, depending on if you want to share exact position, name of fishing water or hide all location data.',
  },
  {
    id: '2',
    title: 'Is it free to use Fishbrain?',
    body: `The free version of the app provides entry-level information about fishing waters and gives preview access to the best times to fish, best baits and weather conditions for ${FREE_TRIAL_DAYS} days.`,
  },
  {
    id: '3',
    title: 'Can I cancel Pro anytime?',
    body: 'You can cancel subscription any time in your App Store or Google Play subscription center. ',
  },
];
