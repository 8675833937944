import React from 'react';

import { Accordion, IAccordionData } from '../Accordion/Accordion';
import { ExternalLink } from '../Clickables/ExternalLink/ExternalLink';
import { Container } from '../Container/Container';
import { Heading, HeadingVariant } from '../Heading/Heading';
import { twMerge } from 'tailwind-merge';
import { MetaTags } from '../MetaTags/MetaTags';
import { getFAQMetaData } from './helpers/getFAQMetaData';
import { getFAQPageSchema } from '../../helpers/schema';

export type IFAQData = IAccordionData & { bodyString?: string };

interface IProps {
  children?: React.JSX.Element;
  className?: string;
  faqData: IFAQData[];
  headingVariant?: HeadingVariant;
  id?: string;
  showFAQMetaData?: boolean;
  showMoreFAQ?: boolean;
  title?: string;
}

export const FAQ = ({
  children,
  className,
  faqData,
  headingVariant = 'xl',
  id,
  showFAQMetaData = false,
  showMoreFAQ = true,
  title = 'FAQ',
}: IProps) => {
  const faqMetaData = showFAQMetaData ? getFAQMetaData(faqData) : [];

  return (
    <Container className={twMerge('py-8', className)} id={id} data-testid="FAQSection">
      {showFAQMetaData && (
        <MetaTags sanitizeSchema={false} schema={getFAQPageSchema(faqMetaData)} />
      )}
      <div className="sm:w-col-10 md:w-col-6 flex flex-col gap-8 sm:mx-auto">
        <Heading variant={headingVariant} className="text-center">
          {title}
        </Heading>
        <div className="flex flex-col gap-4">
          <Accordion
            blocksData={faqData}
            className="bg-lake-fog"
            labelClassName="pt-0"
            blockClassName="body-text-sm"
          />
        </div>
        {showMoreFAQ && (
          <div className="mt-4 text-center text-lg">
            <ExternalLink
              variant="buttonTertiary"
              href="https://fishbrain.helpshift.com/hc/en/3-fishbrain---social-fishing-forecast-app/"
            >
              More FAQ
            </ExternalLink>
          </div>
        )}
        {children}
      </div>
    </Container>
  );
};
