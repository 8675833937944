import sanitizeHtml from 'sanitize-html';
import { IFAQSchema } from '../../../helpers/schema';
import { IFAQData } from '../FAQ';

export const getFAQMetaData = (faqData: IFAQData[]): IFAQSchema[] => {
  return faqData.flatMap(({ title, bodyString }) => {
    if (typeof title !== 'string' || !bodyString) {
      return [];
    }
    return {
      name: sanitizeHtml(title),
      acceptedAnswer: sanitizeHtml(bodyString),
    };
  });
};
